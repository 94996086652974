import React from 'react';
import PageRouteContextProvider from '../utils/contexts/PageRouteContext';

const RootLayout: React.FC<React.ReactNode> = ({ children }) => {
  return (
    <PageRouteContextProvider>
      {children}
    </PageRouteContextProvider>
  );
};

export default RootLayout;