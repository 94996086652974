import React, { createContext, useState } from 'react';
import { DisplayRouteName, RouteName } from '../enums';

export interface TPreviousRoute {
  routeName: RouteName;
  displayRouteName: DisplayRouteName;
}

export interface TPreviousRouteContext {
  routeData: TPreviousRoute[];
  handleSetRouteData: (newRoute: TPreviousRoute, oldRoute?: TPreviousRoute) => void;
}

export const PageRouteContext = createContext<TPreviousRouteContext | null>(null);

const PageRouteContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [routeData, setRouteData] = useState<TPreviousRoute[]>([]);

  const handleSetRouteData = (newRoute: TPreviousRoute, oldRoute?: TPreviousRoute) => {
    if (oldRoute) setRouteData(() => [oldRoute, newRoute]);
    else {
      const removedFirstElement = [...routeData].shift();
      const theRestRouteData = [...routeData].filter((item) => item.routeName !== removedFirstElement?.routeName);
      theRestRouteData.push(newRoute);
      setRouteData(theRestRouteData);
    }
  };

  return (
    <PageRouteContext.Provider value={{ routeData, handleSetRouteData }}>
      {children}
    </PageRouteContext.Provider>
  );
};

export default PageRouteContextProvider;